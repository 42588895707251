<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    class="session-preview"
  >
    <div class="photo-container">
      <img
        :src="photoSrc"
        alt="photo"
      />
    </div>
    <div v-if="!showCancel && !showShare" class="default">
      <div class="buttons">
        <div class="button-container button-container-left">
          <img
            @click="toggleShare"
            class="button"
            :src="buttonImgs.share"
            alt="Share"
            oncontextmenu="return false;"
            @mousedown="buttonImgs.share = '/img/buttons/share_button_pressed.png'"
            @mouseup="buttonImgs.share = '/img/buttons/share_button.png'"
          />
        </div>
        <div class="button-container button-container-right">
          <img
            @click="toggleCancel"
            class="button"
            :src="buttonImgs.close"
            alt="Close"
            oncontextmenu="return false;"
            @mousedown="buttonImgs.close = '/img/buttons/close_button_pressed.png'"
            @mouseup="buttonImgs.close = '/img/buttons/close_button.png'"
          />
        </div>
      </div>
    </div>
    <div v-if="showCancel" class="cancel">
      <div class="text">
        Are you sure you want
        <br>to close this page? Your
        <br>
        #TeamPixel Player Card
        <br>
        will be deleted...
      </div>
      <div class="buttons">
        <div class="button-container button-container-left">
          <img
            @click="finishSession"
            class="button"
            :src="buttonImgs.yes"
            alt="Yes"
            oncontextmenu="return false;"
            @mousedown="buttonImgs.yes = '/img/buttons/yes_button_pressed.png'"
            @mouseup="buttonImgs.yes = '/img/buttons/yes_button.png'"
          />
        </div>
        <div class="button-container button-container-right">
          <img
            @click="toggleCancel"
            class="button"
            :src="buttonImgs.no"
            alt="No"
            oncontextmenu="return false;"
            @mousedown="buttonImgs.no = '/img/buttons/no_button_pressed.png'"
            @mouseup="buttonImgs.no = '/img/buttons/no_button.png'"
          />
        </div>
      </div>
    </div>
    <div v-if="showShare" class="share">
      <div class="text">
        Scan this QR code
        <br>
        to download your digital
        <br>
        player card and share
        <br>
        it with your fans
      </div>
      <div class="qr-container">
        <div class="qr-img-container">
          <img class="qr-code" alt="QR Code" :src=qrData />
        </div>
      </div>
      <div class="buttons">
        <div class="button-container">
          <img
            @click="toggleShare"
            class="button"
            :src="buttonImgs.closeInvert"
            alt="Close"
            oncontextmenu="return false;"
            @mousedown="buttonImgs.closeInvert = '/img/buttons/close_button.png'"
            @mouseup="buttonImgs.closeInvert = '/img/buttons/close_button_pressed.png'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from 'qrcode';

export default {
  name: 'SessionPreview',
  computed: {
    backgroundStyle() {
      let backgroundUrl = '';

      if (this.$store.state.currentBatch) {
        if (String(this.$store.state.currentBatch.input_file).startsWith('http')) {
          backgroundUrl = this.$store.state.currentBatch.input_file;
        } else {
          backgroundUrl = `${process.env.VUE_APP_SERVER_ADDRESS}${this.$store.state.currentBatch.input_file}`;
        }
      }

      return `background-image: url("${backgroundUrl}")`;
    },
    photoSrc() {
      if (this.$store.state.currentBatch) {
        if (String(this.$store.state.currentBatch.photo_file).startsWith('http')) {
          return this.$store.state.currentBatch.photo_file;
        }

        return `${process.env.VUE_APP_SERVER_ADDRESS}${this.$store.state.currentBatch.photo_file}`;
      }

      return '';
    },
  },
  data() {
    return {
      showCancel: false,
      showShare: false,
      qrData: null,
      timeout: null,
      timeoutTime: process.env.VUE_APP_TIMEOUT ? Number(process.env.VUE_APP_TIMEOUT) : null,
      buttonImgs: {
        close: '/img/buttons/close_button.png',
        closeInvert: '/img/buttons/close_button_pressed.png',
        no: '/img/buttons/no_button.png',
        share: '/img/buttons/share_button.png',
        yes: '/img/buttons/yes_button.png',
      },
    };
  },
  methods: {
    toggleCancel() {
      this.showCancel = !this.showCancel;
    },
    toggleShare() {
      QRCode.toDataURL(
        `${process.env.VUE_APP_SERVER_ADDRESS}?id=${this.$store.state.currentBatch.id}`,
        {
          scale: 8,
        },
      ).then((i) => {
        this.qrData = i;
        this.showShare = !this.showShare;
      });
    },
    finishSession() {
      this.qrData = null;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      const url = `
        ${process.env.VUE_APP_SERVER_ADDRESS}/api/sessions/event-sessions/${this.$store.state.currentBatch.id}/
      `;

      axios.patch(
        url,
        {
          complete: true,
        },
        {
          headers: {
            Authorization: `Token ${this.$store.state.serverToken}`,
          },
        },
      )
        .then(() => {
          this.removeListeners();
          this.$store.commit('clearCurrentBatch');
          this.$router.push('/');
        });
    },
    setupTimeout() {
      if (!this.timeoutTime) {
        return;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.finishSession, this.timeoutTime);
    },
    setupListeners() {
      // eslint-disable-next-line no-restricted-globals
      addEventListener('mousemove', this.setupTimeout);
    },
    removeListeners() {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('mousemove', this.setupTimeout);
    },
  },
  mounted() {
    this.setupListeners();
    this.setupTimeout();
  },
};
</script>

<style scoped>
.default, .cancel, .share {
  height: 100%;
  position: absolute;
  width: 100%;
}

.cancel, .share {
  background-color: #ffffffb2;
}

.cancel {
  align-items: center;
  display: flex;
  justify-content: center;
}

.session-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 90%;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 50%;
}

.button-container-left {
  justify-content: right;
}

.button-container-left .button {
  margin-right: 12%;
}

.button-container-right {
  justify-content: left;
}

.button-container-right .button {
  margin-left: 12%;
}

.button {
  width: 53%;
}

.button:hover {
  filter: grayscale(100);
}

.text {
  color: #3c3c3c;
  font-size: 3.9vh;
  font-weight: 500;
  letter-spacing: -.03em;
  margin: auto;
  width: 100%;
}

.qr-container {
  align-items: center;
  height: 100%;
  position: absolute;
  top: 47vh;
  width: 100%;
}

.qr-img-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.qr-code {
  height: auto;
  width: 43.5%;
}

.share .text {
  padding-top: 25vh;
  width: 90%;
}

.photo-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}

.photo-container img {
  width: 81%;
}
</style>
