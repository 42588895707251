import { createStore } from 'vuex';

export default createStore({
  state: {
    batchQueue: [],
    currentBatch: null,
    initialBatchLoaded: false,
    serverToken: process.env.VUE_APP_SERVER_TOKEN,
  },
  getters: {
  },
  mutations: {
    setInitialBatches(state, batches) {
      if (batches.length) {
        state.currentBatch = batches.shift();
        if (batches.length) {
          state.batchQueue = batches;
        }
      }
      state.initialBatchLoaded = true;
    },
    addBatch(state, batch) {
      const { batchQueue } = state;
      batchQueue.push(batch);
      state.batchQueue = batchQueue;
    },
    loadNewBatch(state) {
      const { batchQueue } = state;
      if (batchQueue.length) {
        [state.currentBatch] = batchQueue;
        batchQueue.shift();
        state.batchQueue = batchQueue;
      }
    },
    clearCurrentBatch(state) {
      state.currentBatch = null;
    },
  },
  actions: {
  },
  modules: {
  },
});
