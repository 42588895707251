<template>
  <div class="home">
    <div class="photo-container">
      <img
        src="@/assets/holding_photo.jpg"
        alt="placeholder photo"
      />
    </div>
    <div class="text">
      Turn yourself into
      <br>
      a collectable by capturing
      <br>
      your official #TeamPixel
      <br>
      Player Card on Google Pixel 7
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      batchInQueue: false,
    };
  },
  methods: {
    startNewSession() {
      this.$store.commit('loadNewBatch');
      this.$router.push('/preview');
    },
  },
  mounted() {
    if (this.$store.state.batchQueue.length || this.$store.state.currentBatch) {
      this.startNewSession();
    }

    this.$store.subscribe((mutation) => {
      if (mutation.type === 'setInitialBatches' && this.$store.state.currentBatch) {
        this.startNewSession();
      }

      if (mutation.type === 'addBatch' && !this.$store.state.currentBatch) {
        this.startNewSession();
      }
    });
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  position: absolute;
  width: 100%;
}

.photo-container {
  height: 100%;
  position: absolute;
  top: 25%;
  width: 100%;
}

.photo-container img {
  width: 47%;
}

.text {
  color: #fff;
  font-size: 3.5vh;
  font-weight: 500;
  position: absolute;
  top: 65vh;
  width: 100%;
}
</style>
